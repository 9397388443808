.catalog {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.catalog .header-search {
  padding: 0;
  justify-content: space-between;
}
.catalog .header-search,
.catalog .hideMatchings,
.catalog .importProducts {
  background: #2a2a2a;
}
.catalog .marqueFilter button {
  background: #c4c4c4;
}
.catalog .marqueFilter .list li span::after {
  border-color: #fe9704;
}

.catalog #stats {
  padding: 0 0 0 10px;
  text-align: left;
}

.catalog .sortOptions {
  min-width: 160px;
}

.catalog  .search {
  margin: auto 0;
}
.catalog .search,
.catalog .selected-filters {
  width: 90%;
}
.catalog .search input {
  height: auto;
  padding-top: 4px;
  padding-bottom: 4px;
}
.catalog .marqueFilter {
  width: 100%;
}

.catalog .selected-filters:empty {
  display: none;
}

.catalog .marqueFilter button {
  border-left: 0;
  border-right: 0;
  border-color: #afafaf;
}

.catalog .marqueFilter .select {
  text-transform: uppercase;
  font-weight: bold;
  
}

.catalog .selected-filters {
  margin-bottom: auto;
}
.catalog .selected-filters a {
  background-color:#c4c4c4;
  color: #2a2a2a;
  min-height: 20px;
  padding: 0 5px;
  font-size: 0.8em;
}
.catalog .selected-filters a:hover {
  background-color: #a2a2a2;
}
.catalog .selected-filters a span:last-child {
  border-color: #d8d8d8;
}

.catalog .meta-info {
  position: sticky;
  top: 0;
  background: #FFFFFF;
}

.catalog .es-results .product-title {
  text-overflow: inherit;
  overflow: visible;
  white-space: normal;
}
.catalog .es-results .product-title > span {
  display: flex;
  justify-content: space-between;
}
.catalog .nbMatchingBullet {
  margin-left: 10px;
  display: inline-block;
  flex: 0 0 17px;
  height: 17px;
  text-align: center;
  color: #ffffff;
  background: #afafaf;
  border-radius: 50%;
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  align-self: flex-end;
}
.catalog-item:hover .nbMatchingBullet,
.catalog-item.active .nbMatchingBullet {
  background: #fe9704;
}
.catalog .nbMatchingBullet.undefined {
  background: #f9f9f9;
}

.catalog .es-results .catalog-item {
  min-height: 88px;
  cursor: pointer;
}

.catalog .es-results .catalog-item:hover,
.catalog .es-results .catalog-item.active {
  background: #fef5ec url('/images/arrow-selected.png') no-repeat 96% center;
}

.catalog .catalog-item article {
  width: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.catalog-item .seePageLink {
  display: inline;
  visibility: hidden;
  color: #fe9704;
  outline: none;
}

.catalog-item:hover .seePageLink,
.catalog-item.active .seePageLink {
  visibility: visible;
}

.catalog .hideMatchings,
.catalog .importProducts {
  color: #ffffff;
  padding: 0px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.9em;
}
.catalog .hideMatchings .labelSwitch
{
  flex: 0 0 75%;
}
.catalog .importProducts {
  padding: 0;
}

.catalog .button__orange {
  background: #fe8904;
  padding: 8px 10px;
  text-align: center;
  flex: 1;
  line-height: 1em;
  outline: none;
  cursor: pointer;
}
.catalog .importProducts .button__orange:hover {
  background: #fe9704;
}

.catalog .switchRoot .MuiSwitch-bar-16 {
  background-color: #fefefe;
}