header {
  padding: 2vw 3vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.8em;
}

header > * {
  flex-grow: 1;
  text-align: center;
}

header .logo {
  white-space: nowrap;
}

header .logo img {
  height: 65px;
}

header .mainSearch {
  background: #FFF;
  margin: 0 auto;
}

/* On the demo page */
header.demo .mainSearch {
  flex-grow: 6;
}

header.demo .subscribe {
  color: #f89527;
  font-style: italic;
  font-size: 1em;
  text-transform: uppercase;
  cursor: pointer;
}
