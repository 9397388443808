.priceLabel {
    font-size: 16px;
}

.messageLabel {
    text-align: right;
    padding-right: 10px;
}

.messageLabel .subLabel {
    font-size: 11px;
    font-style: italic;
    color: #858585;
}

.messageLabel .wrench .fa-wrench {
    color: #858585;
}
.messageLabel .wrench {
    font-style: italic;
}

.messageLabel .labelGreen{
    text-align: right;
    color :#097212;
    font-size: 12px;
}