.sitestatistics table {
    border-collapse: collapse;
    width: 99%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sitestatistics th, .sitestatistics td {
    border: 1px solid black;
    text-align: center;
    white-space: nowrap;
    padding: 8px;
}
.sitestatistics .allowWrap {
    white-space: initial;
}

.sitestatistics th {
    line-height: 50px;
}
.sitestatistics td {
    line-height: 25px;
}

.sitestatistics tr.firstLine td[rowspan="1"] {
    border-bottom: 0;
}
.sitestatistics tr.secondLine td {
    border-top: 0;
}
