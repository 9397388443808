.url-matching {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.url-matching form,
.url-matching .result-area {
  display: flex;
  flex: 0.5;
  flex-direction: column;
}

.url-matching form {
  justify-content: space-evenly;
  align-items: center;
}

.url-matching h2 {
  color: #2a2a2a;
}

.url-matching input[type="url"] {
  font-size: 1.2em;
  width: 70%;
  padding: 2vh 1vw;
}

.url-matching .submit {
  background-color: #fe9704;
  color: #FFFFFF;
  font-size: 1.2em;
}
.url-matching .submit:hover {
  background-color: #ec8a01;
}