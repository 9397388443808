
.search-matching {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#stats {
  display: block;
  text-align: right;
  width: 100%;
  padding: 0 5px 5px 0;
}

.search-matching .header-search {
  flex-shrink: 0;
  align-items: center;
  flex-direction: row;
}

.search-matching .header-search .search {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.switch-tab {
  align-self: flex-end;  
}

.switch-tab button {
  color: #2196f3;
}

.prixan-search-field {
  width: 100%;
}

.prixan-search-field input {
  border: 2px solid #666;
  background-color: #FFF;
  font-size: 18px;
  font-weight: bold;
  padding-left: 40px;
}
.prixan-search-field ::placeholder {
  color: #AAA;
}

.prixan-search-field input + div div:nth-child(2) {
  padding-left: 12px;
}
.prixan-search-field input + div div:nth-child(2) svg {
  height: 20px;
}

.matching-body {
  max-height: calc(100% - 110px); /* size of header */
  flex: 1;
  display: flex; 
  flex-direction: row;
}

.es-results .product-title {
  font-weight: normal;
  font-size: 0.9rem;
  white-space: normal;
}
.es-results .product-title span {
  cursor: pointer;
}

.es-results .product-image {
  height: 60px;
  width: 60px;
}

.es-results a {
  border-color: #E1E1E1;
}


.filters {
  border-left: 1px solid #e1e1e1;
  align-self: flex-end;
  width: 20vw;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1vh 1vw;
}
.filters > div {
  margin-bottom: 2vh
}
.filters ul.list {
  /*max-height for two filters = viewport - header - margins - manual offset*/
  max-height: calc((100vh - 110px - 2vh - 250px) / 2);
}
.filters ul.list.only-list {
  max-height: calc(100vh - 110px - 2vh - 120px);
}
.filters > div:last-child {
  margin-bottom: 0;
  justify-self: flex-end;
}
.filters .title {
  text-transform: uppercase;
}

.filters .list li {
  height: 25px;
  line-height: 0.8em;
}
.filters .label, .filters .count {
  font-size: 0.9em;
}
.filters .checkbox + .label::before {
  color: #fe9704;
}
.filters .checkbox:checked + .label::before,
.filters .checkbox:hover + .label::before,
.filters .checkbox:focus + .label::before {
  border-color: #fe9704;
}
.filters .checkbox:focus + label::before {
  box-shadow: 0 0 0 1px #fe9704;
}
.filters .checkbox + .label::after {
  border-color: #fe9704;
}

.filters .input {
  padding: 4px 12px;
  height: auto;
}
