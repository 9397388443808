.popup {
  display: flex;
  height: 100vh;
  background: white;
}

.popup .catalog {
  flex-basis: 25vw;
  flex-shrink: 0;
  max-width: 400px;
  border-right: 1px solid #e1e1e1;
}

.popup .url-matching,
.popup .search-matching {
  flex: 1 1 auto;
  max-height: 100%;
}

.chooseProduct {
  align-self: center;
  margin: 0 0 20vh 20px;
}

.header-search {
  height: 110px;
  background: #F8F8F8;
  border-bottom: 1px solid #e1e1e1;
  padding: 0 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.scrollable {
  overflow-y: auto;
}
.scrollable_inflex {
  flex: 1 1;
}

mark {
  background-color: transparent;
  font-weight: bold;
}

.popup .selected-filters a {
  max-width: 100%;
}

/* width */
.scrollable::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.scrollable::-webkit-scrollbar-track {
  background: #e1e1e1; 
  border-radius: 5px;
}

/* Handle */
.scrollable::-webkit-scrollbar-thumb {
  background: #6b6b6b; 
}

/* Handle on hover */
.scrollable::-webkit-scrollbar-thumb:hover {
  background: #fe9704; 
  cursor: pointer;
}

/* scrollbar arrow buttons */
.popup::-webkit-scrollbar-button {
  display:none;
}