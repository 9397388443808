.search-stats {
  justify-content: flex-end;
}

#root #page {
  display: flex;
  justify-content: space-between;
}

#root .results {
  flex: 3;
  overflow: hidden;
}
#root #filters {
  margin-left: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
}
#root #filters .sticky {
  position: sticky;
  top: 165px;
}
#root #filters .sticky > * {
  margin-bottom: 30px;
}

#root .products {
  flex-direction: row;
  flex-wrap: wrap;
}

.products .product-item {
  margin: 10px;
  border: none;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
  width: calc(50% - 20px);
}
.products .product-item:hover {
  box-shadow: 0 0 8px 1px rgba(0,0,0,.3)
}
.products .product-item h2 {
  margin: 0;
  padding: 0;
}
.products .product-item article {
  width: calc(100% - 120px);
}

.product-img {
  max-width: 120px;
  max-height: 80px;
}

/* Landscape phones and down */
@media (max-width: 480px) {
  .product-img {
    max-width: 25vw;
  }
  .product-item span {
    padding-right: 0.5em;
    font-size: 0.9em;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {}

/* Portrait tablet to landscape and desktop */
@media (max-width: 979px) {
  header {
    flex-direction: column;
    justify-content: space-between;
    position: static;
  }
  header .mainSearch {
    width: 100%;
    margin: 1.3em auto 0 auto;
    order: 2;
  }
  header .subscribe {
    order: 0;
    align-self: flex-end;
  }
  header .logo {
    order: 1;
  }
  .products .product-item {
    width: calc(100% - 20px);
  }
  #root #filters {
    display: none;
  }
}
.sortOptions{
  display: none;
}
.search-stats{
  justify-content: end;
}

/* Large desktop */
@media (min-width: 1200px) {}
